import React from "react";

const TransactionDetails = ({ transactionDetails }) => {
  return (
    <div className="w-100 d-flex justify-content-center my-5">
      <div className="transaction-details-wrapper">
        <div className="fw-bold">Transaction Details</div>
        <hr className="border border-dark" />
        <ul className="list-unstyled">
          {transactionDetails &&
            transactionDetails.length > 0 &&
            transactionDetails.map(({ key, value }) => (
              <li className="d-flex " key={key}>
                <div className="text-secondary">{`${key}:`}</div>
                <div className="pl-2 fw-bold">{value}</div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default TransactionDetails;

import React, { useState, useEffect } from "react";
import HeadingComponent from "../template/_common/headingComponent";
import {
  fetchUserProfileApi,
  updateUserProfileApi,
  userLevelProgressApi,
  userViewQuestionsApi,
} from "./_userActions";
import { withRouter } from "react-router-dom";
import { globalAlert, globalLoader } from "../actions/commonActions";
import { ProgressBar } from "react-bootstrap";
import { logOut } from "../_shared/commonFunction";
import { Autocomplete, TextField } from "@mui/material";

const MissingProfile = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    callBasicApi();
  }, []);

  const levelProgressRouting = async () => {
    let res = await userLevelProgressApi();
    if (res?.data?.status === 200) {
      if (res?.data?.data?.haltProgress?.halt) {
        props.history.push(`/user/video`);
      } else if (
        !res?.data?.data?.haltProgress?.halt &&
        res?.data?.data?.quizLevelPassed
      ) {
        props.history.push(`/user/dashboard`);
      } else {
        props.history.push(`/user/video`);
      }
    } else if (res?.data?.status === 401) {
      globalAlert("error", res?.data?.message);
      logOut(props.history, "/user/login");
    } else {
      globalAlert("error", res?.data?.message);
    }
  };

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      const userProfileApi = await fetchUserProfileApi();
      if (
        userProfileApi?.data?.status === 200 &&
        userProfileApi?.data?.data?.questions?.show
      ) {
        props.history.push(`/user/missing-profile`);
        setQuiz(userProfileApi?.data?.data?.questions?.list);
      } else {
        await levelProgressRouting();
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const handleAnswerChange = (questionType, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionType]: value,
    }));
  };

  const handleSubmit = async () => {
    if(isSubmitDisabled) {
      globalAlert("error", `Please answer the required questions`);
    } else {
      const payload = {
        questions: Object.keys(answers).map((type) => ({
          type,
          options: Array.isArray(answers[type]) ? answers[type] : [answers[type]],
        })),
      };
  
      console.log("Payload:", JSON.stringify(payload));
      // Submit payload to API
      const res = await updateUserProfileApi(payload);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        await levelProgressRouting();
      } else {
          globalAlert("error", res?.data?.message);
      }
    }
  };

  // Check if any required question is unanswered
  const isSubmitDisabled = quiz?.some(
    (question) => question.required && (!answers[question.type] || answers[question.type].length === 0)
  );

  return (
    <>
      <div>
        <HeadingComponent text="Additional Registration Questions" />
      </div>
      <div style={{ backgroundColor: "#f8f9fa", padding: "10px 16px" }}>
        {quiz.map((question, index) => {
          const isFirst = index === 0;
          const isLast = index === quiz.length - 1;
          const questionClass = `quiz-question-wrapper mx-auto 
           ${isFirst ? "first-question mx-auto" : ""} 
           ${isLast ? "last-question mx-auto" : ""} 
           ${!isFirst && !isLast ? "quiz-question-wrapper mx-auto" : ""}`;
          return (
            <div key={index} className={questionClass.trim()}>
              <div className="quiz-question">
                {index + 1}. {question.title}{question?.required && <span className="text-danger">*</span>}
              </div>
              {/* <div className="quiz-answer-group row "> */}
              {question.choiceType === "single_select" ? (
                <select
                  value={answers[question.type] || ""}
                  onChange={(e) =>
                    handleAnswerChange(question.type, e.target.value)
                  }
                  className="form-control"
                >
                  <option value="">Select an option</option>
                  {question.options.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              ) : question.choiceType === "multi_select" ? (
                <select
                  multiple
                  value={answers[question.type] || []}
                  onChange={(e) =>
                    handleAnswerChange(
                      question.type,
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                  className="form-control"
                >
                  {question.options.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              ) : null}
              {/* </div> */}
            </div>
          );
        })}
      </div>
      <div className="fixed-bottom stripe py-3 border-top fixed-bottom-own">
        <div className="col-md-12 text-center">
          <button className="btnFooter footer-width" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default withRouter(MissingProfile);

import React from "react";
import { validEmail } from "../../_shared/commonFunction";
import TextField from "@mui/material/TextField";

const InputComponent = (props) => {
  const handleChange = async (ev) => {
    const { value } = ev.target;
    const { name } = props;
    if (
      ((props?.maxLength && Number(props?.maxLength) >= value.length) ||
        !props?.maxLength) &&
      props?.handleCallback
    ) {
      const caret = ev.target.selectionStart;
      const element = ev.target;
      let flag;

      if (props?.onlyNumber) {
        const regex = /^[0-9]+$/;
        if (regex.test(value) || value === "") {
          // setInputValue(value);// eslint-disable-next-line
          // eslint-disable-next-line
          props?.handleCallback(value);
        }
        return;
      }

      if (name === "phone") {
        const regex = /^[0-9\b]+$/;
        if (regex.test(value) || value === "") {
          // setInputValue(value);
          // eslint-disable-next-line
          props?.handleCallback(value);
        }
      } else if (name === "email") {
        let valid = await validEmail(value);
        if (valid) {
          flag = false;
        } else {
          flag = true;
        }
        // setInputValue(value);
        // eslint-disable-next-line
        props?.handleCallback(value, flag);
        element.selectionEnd = caret;
      } else if (props?.capsValue) {
        // eslint-disable-next-line
        props?.handleCallback(value.toUpperCase());
      } else {
        // setInputValue(value);
        // eslint-disable-next-line
        props?.handleCallback(value);
      }
    }
  };
  // return (
  //   <div className="input-wrapper">
  //     <label htmlFor="textBox" className={`input-label ${props?.theme}`}>
  //       {props?.label}
  //     </label>
  //     <input
  //       name={props?.name}
  //       type={props?.type}
  //       className={`input-box ${props?.theme} ${props?.label === "Post Code" ? "text-uppercase" : ""
  //         }`}
  //       placeholder={props?.placeholder}
  //       maxLength={props?.maxLength ? props?.maxLength : null}
  //       disabled={props?.disabled}
  //       value={props?.value ? props?.value : ""}
  //       autoComplete={props?.autoComplete ? props?.autoComplete : null}
  //       onChange={(ev) => handleChange(ev)}
  //     />
  //   </div>
  // );
  return (
    <>
      <TextField
        id="outlined-basic"
        label={props?.label}
        variant="outlined"
        fullWidth
        name={props?.name}
        type={props?.type}
        className={`input-box ${props?.theme}`}
        placeholder={props?.placeholder}
        maxLength={props?.maxLength ? props?.maxLength : null}
        disabled={props?.disabled}
        // value={props?.value ? props?.value : ""}
        value={
          props.value !== undefined && props.value !== null ? props.value : ""
        }
        autoComplete={props?.autoComplete ? props?.autoComplete : null}
        onChange={(ev) => handleChange(ev)}
        sx={{
          input: { color: props?.theme === "primary" ? "white" : "#332876" },
        }}
        InputLabelProps={{
          style: {
            color: props?.theme === "primary" ? "white" : "#332876",
            paddingBottom: props?.theme === "primary" ? "0" : "1px",
          },
        }}
        inputProps={{
          className: props?.className || "",
          maxLength: props?.maxLength || "",
        }}
        multiline={props?.multiline || false}
        rows={props?.rows || 1}
      />
    </>
  );
};

export default InputComponent;

import React from "react";

export default function StocksTransactionTable(props) {
  console.log(props);
  return (
    <div className="table-wrapper px-3 pt-3">
      <div className="table-wrapper-scrol-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-admin">
            <thead className="table-head">
              <tr>
                <th>
                  <span>Sl. No.</span>
                </th>
                <th>
                  <span>Quantity</span>
                </th>
                <th>
                  <span>Product Name</span>
                </th>
                <th>
                  <span>Date</span>
                </th>
                <th>
                  <span>Time</span>
                </th>
                <th>
                  <span>Notes</span>
                </th>
                <th>
                  <span>User</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props?.dataList?.map((data, i) => {
                let dateNow = "",
                  timeNow = "";
                if (data?.timestamp) {
                  const { timestamp } = data;
                  const dateObj = new Date(timestamp);
                  dateNow = dateObj.toLocaleDateString("en-UK", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  });
                  timeNow = dateObj.toLocaleTimeString("en-UK", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });
                }

                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{data?.stockQuantity}</td>
                    <td>{data?.orgProduct?.name}</td>
                    <td>{dateNow}</td>
                    <td>{timeNow}</td>
                    <td>{data?.notes || "-"}</td>
                    <td>{data?.adminUser?.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function TermsModal({ open, onCloseModal, heading, body }) {
  return (
    <Modal
      showCloseIcon={false}
      classNames={{
        modal: "modal-own",
        root: "modal-own-wrapper confirm-modal",
      }}
      open={open}
      onClose={() => onCloseModal(false)}
      center
      closeOnOverlayClick={true}
    >
      <div className="text-center font-weight-bold py-3">{heading}</div>
      <div className="text-center py-2">{body}</div>
      <div className="w-100 border-top d-flex align-items-center justify-content-between pt-3 mt-3">
        <div className="w-100 d-flex justify-content-center">
          <button
            className="btn btn-own grey-btn px-4 "
            style={{ minWidth: "110px", minHeight: "50px" }}
            onClick={() => onCloseModal(false)}
          >
            Cancel
          </button>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button
            onClick={() => onCloseModal(true)}
            className="btnFooter "
            style={{ minWidth: "110px" }}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}

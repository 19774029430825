import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import InputComponent from "../_common/inputComponent";

const AccessCodeModal = (props) => {
  const [accessCode, setAccessCode] = useState("");
  const [emptyAccessCode, setEmptyAccessCode] = useState(false);
  const [allowedTransactionLimit, setAllowedTransactionLimit] = useState(1);
  const [isUnlimitedTransaction, setIsUnlimitedTransaction] = useState(false);
  const handleValidation = () => {
    let flag = true;
    if (!accessCode) {
      setEmptyAccessCode(true);
      flag = false;
    }
    if (accessCode.length < 5) {
      flag = false;
    }
    return flag;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        accessCode: accessCode,
        allowedTransactionLimit:
          isUnlimitedTransaction && props?.askTxnsAllowed?.showUnlimitedOption
            ? -1
            : parseInt(allowedTransactionLimit),
        accessType: props?.askTxnsAllowed?.show ? "register" : "",
      };
      // eslint-disable-next-line
      props.closeModal(obj);
    }
  };

  const handleTransactionLimit = (val) => {
    const positiveIntegerPattern = /^[0-9]*$/;
    if (val === "" || positiveIntegerPattern.test(val)) {
      setAllowedTransactionLimit(val);
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setAllowedTransactionLimit(0);
    }
    setIsUnlimitedTransaction(checked);
  };

  return (
    <>
      <Modal
        open={props?.open}
        onClose={() => {
          // eslint-disable-next-line
          props?.closeModal();
        }}
        center
        classNames={{
          modal: "modal-own",
          root: "modal-own-wrapper black-cross-btn-modal",
        }}
      >
        <form className="form-own px-3 pt-5" onSubmit={handleSubmit}>
          <div className="row px-2">
            <div className="col-12 px-2">
              <div className="form-own-group mb-2">
                <label htmlFor="name" className="form-own-label">
                  Please Enter Access Code
                </label>
                <input
                  id="accessCode"
                  name="accessCode"
                  placeholder="Please Enter Access Code"
                  onChange={(ev) => {
                    setAccessCode(ev.target.value);
                  }}
                  value={accessCode}
                  className="form-control"
                  maxLength="20"
                />
                <div className="error-text">
                  {!accessCode && emptyAccessCode && (
                    <span>Access Code is required</span>
                  )}
                  {accessCode && accessCode.length < 5 && (
                    <span>More than 5 characters required</span>
                  )}
                </div>
              </div>
              {props?.askTxnsAllowed?.show && (
                <Box
                  display={{ xs: "flex" }}
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent={{ xs: "space-between" }}
                  alignItems={{ xs: "center" }}
                >
                  <div className="mb-2">
                    <InputComponent
                      disabled={isUnlimitedTransaction}
                      placeholder="No of transactions"
                      label="No of transactions"
                      type="number"
                      name="allowedTransactionLimit"
                      inputFor="text"
                      theme="white"
                      value={allowedTransactionLimit}
                      autoComplete="off"
                      maxLength={3}
                      handleCallback={(val) => handleTransactionLimit(val)}
                    />
                  </div>
                  {props?.askTxnsAllowed?.showUnlimitedOption && (
                    <div className="mt-2">
                      <FormControlLabel
                        label="Allow unlimited transactions?"
                        control={
                          <Checkbox
                            size="small"
                            checked={isUnlimitedTransaction}
                            onChange={handleCheckboxChange}
                          />
                        }
                      />
                    </div>
                  )}
                </Box>
              )}
            </div>
          </div>
          <div className="admin-btn">
            <button
              onClick={() => props.closeModal()}
              type="button"
              className="btn secondary-btn mr-md-2 mb-md-0 mb-3"
            >
              Back
            </button>
            <button type="submit" className="btn primary-btn ml-md-2">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AccessCodeModal;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
  globalAlert,
  globalLoader,
  geoLocationApi,
} from "../actions/commonActions";
import UserSettingsModal from "../template/user/userSettingsModal";
import {
  viewStoresApi,
  viewIvtsApi,
  userLevelProgressApi,
  userBrandInfo,
  getDashboardApi,
  searchIvtsStoresApi,
} from "./_userActions";
import ScannerComponent from "../template/user/scannerComponent";
import MapComponent from "../template/user/mapComponent";
import MapMarkerComponent from "../template/user/mapMarkerComponent";
import { logOut } from "../_shared/commonFunction";
import { debounce } from "lodash";
import axios from "axios";
import MobileMenu from "../template/user/mobileMenu";
import { useSelector } from "react-redux";
import Abcd from "./userPushNotification";
const Dashboard = (props) => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [mobileView, setMobileView] = useState(true);
  const [currentTabActive, setCurrentTabActive] = useState("map");
  const [latLongObj, setLetLongObj] = useState(null);
  const [storesList, setStoresList] = useState([]);
  const [ivtsList, setIvtsList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  const [defaultProps, setDefaultProp] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [currentLatLng, setCurrentLatLng] = useState(null);
  const [inputVal, setInputVal] = useState("");
  const [view, setView] = useState(false);
  const [brandLogo, setBrandLogo] = useState(null);
  const [menuHeight, setMenuHeight] = useState(50);
  const [searchStoreList, setSearchStoreList] = useState([]);
  const [noList, setNoList] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(0);
  const [active, setActive] = useState(0);

  const userMsgCount = useSelector((state) => state.user.msgCount);

  const ref = useRef();

  useEffect(() => {
    getBrandInfo();
    // document.html.classList.add("overflow-hidden");
    document.documentElement.className = "overflow-hidden-own";
    document.body.classList.add("overflow-hidden-own");
  }, []);

  const getBrandInfo = async () => {
    globalLoader(true);
    let res = await userBrandInfo();
    if (res?.data?.status === 200) {
      setBrandLogo(res?.data?.data?.orgBrandInfo?.brandLogo);
    } else {
      globalAlert("error", res?.data?.message);
    }
    globalLoader(false);
  };

  useEffect(() => {
    mobileAndTabletCheck();
    globalLoader(false);
    if (
      localStorage.getItem("ecauthtoken") &&
      localStorage.getItem("ecsecuretoken")
    ) {
      getGeoLocationFunc();
    } else {
      props.history.push("/user/login");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    mobileAndTabletCheck();
  }, [currentMenu]);
  const callBasicApi = async (val = null) => {
    try {
      let interNalVal;
      if (val) {
        interNalVal = val;
      }
      globalLoader(true);
      getDashboardApi();
      let res = await userLevelProgressApi();
      if (res?.data?.status === 200) {
        if (!res?.data?.data?.skipVideosPage) {
          props.history.push(`/user/video`);
        } else if (
          res?.data?.data?.skipVideosPage &&
          !res?.data?.data?.skipQuizPage
        ) {
          props.history.push(`/user/quiz`);
        } else if (res?.data?.data?.quizLevelPassed) {
          globalLoader(true);

          let resDashboard = await Promise.all([
            viewStoresApi(interNalVal ? interNalVal : latLongObj),
            viewIvtsApi(interNalVal ? interNalVal : latLongObj),
          ]);
          if (
            resDashboard[0]?.data?.status === 200 &&
            resDashboard[1]?.data?.status === 200
          ) {
            setNoList("");

            setStoresList(
              resDashboard[0]?.data?.data?.storesList.filter(
                (x) => !x.hideOnMap
              )
            );

            setIvtsList(
              resDashboard[1]?.data?.data?.ivtsList.filter((x) => !x.hideOnMap)
            );
            setView(true);
          } else if (res?.data?.status === 401) {
            globalAlert("error", res?.data?.message);
            logOut(props.history, "/user/login");
          } else {
            setNoList(resDashboard?.data?.message);
            globalAlert("error", resDashboard?.data?.message);
          }
          globalLoader(false);
        } else {
          props.history.push("/user/video");
        }
      } else if (res?.data?.status === 401) {
        globalAlert("error", res?.data?.message);
        logOut(props.history, "/user/login");
      } else {
        globalAlert("error", res?.data?.message);
      }
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const getGeoLocationFunc = async () => {
    console.log("1");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("2", position);

          setLetLongObj({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCurrentLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setDefaultProp({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            zoom: 11,
          });

          callBasicApi({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setAccessDenied(true);
          globalAlert("error", error?.message || "Something went wrong");
        }
      );
    } else {
      globalAlert("error", "Something went wrong please refresh");
    }
  };

  const closeSettingModal = () => {
    setOpenSettingsModal(false);
  };

  const setCurrentTabActiveProps = (val) => {
    setCurrentTabActive(val);
  };
  const mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    setMobileView(check);
  };

  const mapDirection = (ev, val = null) => {
    ev.stopPropagation();
    if (val) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${val?.latitude},${val?.longitude}`,
        "_blank"
      );
    }
  };
  const gotoMyLocation = () => {
    setCurrentTabActive("");
    setLetLongObj(currentLatLng);
    callBasicApi({
      latitude: currentLatLng.lat,
      longitude: currentLatLng.lng,
    });
    setInputVal("");
    setTimeout(() => {
      setCurrentTabActive("map");
    }, 30);
  };

  useEffect(() => {
    if (ref?.current?.offsetHeight) {
      setMenuHeight(ref?.current?.offsetHeight);
    }
  }, [ref]);

  const handleImageLoaded = () => {
    setMenuHeight(ref?.current?.offsetHeight);
  };

  const debouncedSave = useCallback(
    debounce(async (nextValue) => {
      try {
        let resDashboard = await searchIvtsStoresApi(nextValue);

        if (resDashboard?.data?.status === 200) {
          setNoList("");
          setSearchStoreList(
            resDashboard?.data?.data?.list.filter((x) => !x.hideOnMap)
          );
          // setStoresList(
          //   resDashboard?.data?.data?.list.filter((x) => !x.hideOnMap)
          // );
          setSearchList(
            resDashboard?.data?.data?.list.filter((x) => !x.hideOnMap)
          );
        } else if (resDashboard?.data?.status === 401) {
          globalAlert("error", resDashboard?.data?.message);
          logOut(props.history, "/user/login");
        } else {
          globalAlert("error", resDashboard?.data?.message);
          setSearchList([]);
          setSearchStoreList([]);
          setNoList(resDashboard?.data?.message);
        }
      } catch (error) {
        globalAlert("error", "Unauthorised");
        logOut(props.history, "/user/login");
      }
    }, 500),
    []
  );

  const handleChange = (val) => {
    const nextValue = val;

    setInputVal(nextValue);

    debouncedSave({
      latitude: currentLatLng?.lat,
      longitude: currentLatLng?.lng,
      search: nextValue,
    });
  };

  const listClicked = (val) => {
    setInputVal(val?.storeName);
    setLetLongObj({
      lat: val?.latitude,
      lng: val?.longitude,
    });
    setSearchStoreList([]);
  };

  const handleScanner = () => {
    setCurrentTabActive("scanner");
  };

  return (
    (view || accessDenied) && (
      <>
        {/* <Abcd/> */}
        <div className="dashboard-main-wrapper">
          {mobileView && (
            <div className="mobile-header">
              <img
                alt="EC Card"
                className="mobile-logo"
                src="/assets/icons/eC-Card-Logo-Square.png"
              />
              <span>eC-Card</span>
              <img
                alt="org-logo"
                className="mobile-logo"
                src={brandLogo}
                onLoad={handleImageLoaded}
              />
            </div>
          )}

          <ul
            className="d-flex flex-wrap list-unstyled dashboard-tab-wrapper mb-0"
            ref={ref}
          >
            {!mobileView && (
              <>
                {" "}
                <img
                  alt="EC Card"
                  className="dashboard-logo hidden-img"
                  src="/assets/icons/eC-Card-Logo-Square.png"
                  onLoad={handleImageLoaded}
                />
                <img
                  alt="org-logo"
                  className="dashboard-logo hidden-img"
                  src={brandLogo}
                  onLoad={handleImageLoaded}
                />
                {mobileView && !accessDenied && (
                  <li
                    className={`flex-1 ${
                      currentTabActive === "scanner" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTabActive("scanner")}
                  >
                    <div className="dashboard-menu">
                      <span className="dashboard-label">Scanner</span>
                    </div>
                  </li>
                )}
                <li
                  className={`flex-1 ${
                    currentTabActive === "map" ? "active" : ""
                  }`}
                  onClick={() => setCurrentTabActive("map")}
                >
                  {mobileView ? (
                    <div className="dashboard-menu">
                      <span className="dashboard-label">Venues</span>
                    </div>
                  ) : (
                    <div className="dashboard-menu web-menu">
                      <img
                        alt="EC Card"
                        className="dashboard-logo"
                        src="/assets/icons/eC-Card-Logo-Square.png"
                      />

                      <span className="dashboard-label">
                        {currentMenu === 0
                          ? "Venues"
                          : currentMenu === 1
                          ? "Education"
                          : currentMenu === 2
                          ? "Notification"
                          : "Account"}
                      </span>
                      <img
                        alt="EC Card"
                        className="dashboard-logo"
                        src={brandLogo}
                      />
                    </div>
                  )}
                </li>
              </>
            )}
          </ul>

          <div className="d-flex flex-wrap position-relative">
            {currentTabActive == "scanner" && (
              <ScannerComponent
                setCurrentTabActive={setCurrentTabActiveProps}
              />
            )}

            {currentTabActive === "map" && (
              <>
                {mobileView ? (
                  currentMenu === 0 ? (
                    <div
                      className="flex-1 mobile-full-view position-relative"
                      style={{ height: "50vh" }}
                    >
                      {/* <MapComponent
                        storesList={storesList}
                        latLongObj={latLongObj}
                        setInputVal={handleChange}
                        inputVal={inputVal}
                        open={true}
                        setOpen={setCurrentData}
                        callBasicApi={callBasicApi}
                        setLetLongObj={setLetLongObj}
                        mobileView={mobileView}
                        maxHeight={menuHeight}
                        listClicked={listClicked}
                        searchStoreList={searchStoreList}
                        closeList={() => setSearchStoreList([])}
                      /> */}
                      <MapMarkerComponent
                        storesList={
                          active === 1
                            ? ivtsList
                            : active === 0
                            ? storesList
                            : searchList
                        }
                        latLongObj={latLongObj}
                        setInputVal={handleChange}
                        inputVal={inputVal}
                        open={true}
                        setOpen={setCurrentData}
                        callBasicApi={callBasicApi}
                        setLetLongObj={setLetLongObj}
                        mobileView={mobileView}
                        maxHeight={menuHeight}
                        listClicked={listClicked}
                        searchStoreList={[]}
                        closeList={() => setSearchStoreList([])}
                        active={active}
                      />
                      <div
                        className="my-location-wrapper cursor"
                        onClick={gotoMyLocation}
                      >
                        <img src="/assets/icons/my-location.png" alt="icon" />
                      </div>

                      {/* <div className="dashboard-controls-wrapper">
                          <div
                            className="setting-own"
                            onClick={() => {
                              setOpenSettingsModal(true);
                            }}
                          >
                            <i className="cursor fas fa-cog"></i>
                          </div>
                        </div> */}
                    </div>
                  ) : null
                ) : (
                  <div className="flex-1 position-relative">
                    {currentMenu === 0 ? (
                      <>
                        {/* <MapComponent
                          storesList={storesList}
                          latLongObj={latLongObj}
                          setInputVal={handleChange}
                          inputVal={inputVal}
                          open={true}
                          setOpen={setCurrentData}
                          callBasicApi={callBasicApi}
                          setLetLongObj={setLetLongObj}
                          mobileView={mobileView}
                          maxHeight={menuHeight}
                          listClicked={listClicked}
                          searchStoreList={searchStoreList}
                          closeList={() => setSearchStoreList([])}
                        /> */}
                        <MapMarkerComponent
                          storesList={
                            active === 1
                              ? ivtsList
                              : active === 0
                              ? storesList
                              : searchList
                          }
                          latLongObj={latLongObj}
                          setInputVal={handleChange}
                          inputVal={inputVal}
                          open={true}
                          setOpen={setCurrentData}
                          callBasicApi={callBasicApi}
                          setLetLongObj={setLetLongObj}
                          mobileView={mobileView}
                          maxHeight={menuHeight}
                          listClicked={listClicked}
                          searchStoreList={[]}
                          closeList={() => setSearchStoreList([])}
                          active={active}
                        />
                        <div
                          className="my-location-wrapper cursor"
                          onClick={gotoMyLocation}
                        >
                          <img src="/assets/icons/my-location.png" alt="icon" />
                        </div>

                        {/* <div className="dashboard-controls-wrapper">
                          <div
                            className="setting-own"
                            onClick={() => {
                              setOpenSettingsModal(true);
                            }}
                          >
                            <i className="cursor fas fa-bars"></i>
                          </div>
                        </div> */}
                      </>
                    ) : null}
                  </div>
                )}
                {/* store list wrapper */}
                {currentMenu !== 0 || mobileView ? (
                  <MobileMenu
                    noList={noList}
                    storesList={
                      active === 1
                        ? ivtsList
                        : active === 0
                        ? storesList
                        : searchList
                    }
                    currentData={currentData}
                    getLetLongObj={(lat, lng) =>
                      setLetLongObj({ lat: lat, lng: lng })
                    }
                    setMapDirection={(ev, store) => mapDirection(ev, store)}
                    openScanner={handleScanner}
                    currentMenu={(id) => setCurrentMenu(id)}
                    activeMenu={currentMenu}
                    mobileView={mobileView}
                    searchChange={handleChange}
                    searchList={searchList}
                    inputVal={inputVal}
                    setActive={setActive}
                    active={active}
                    {...props}
                  />
                ) : (
                  <>
                    <div
                      className={`store-list-wrapper active full ${
                        mobileView ? "mobile-view w-100" : ""
                      }`}
                    >
                      <div className="venues-menu">
                        <div
                          className={`menu cursor ${
                            active === 0 ? "active" : null
                          }`}
                          onClick={() => setActive(0)}
                          style={
                            active === 0
                              ? { borderBottom: "3px solid #08BBCF" }
                              : null
                          }
                        >
                          <div
                            className="img-wrapper"
                            style={{ backgroundColor: "#08BBCF" }}
                          >
                            <img
                              src={"/assets/icons/stores.png"}
                              alt="stores"
                            />
                          </div>
                          <p>Stores</p>
                        </div>
                        <div
                          className={`menu cursor ${
                            active === 1 ? "active" : null
                          }`}
                          onClick={() => setActive(1)}
                          style={
                            active === 1
                              ? { borderBottom: "3px solid #F06292" }
                              : null
                          }
                        >
                          <div
                            className="img-wrapper"
                            style={{ backgroundColor: "#F06292" }}
                          >
                            <img
                              src={"/assets/icons/vending.png"}
                              alt="vending"
                            />
                          </div>

                          <p>Vending Machines</p>
                        </div>
                        <div
                          className={`menu cursor ${
                            active === 2 ? "active" : null
                          }`}
                          onClick={() => setActive(2)}
                          style={
                            active === 2
                              ? { borderBottom: "3px solid #F29900" }
                              : null
                          }
                        >
                          <div
                            className="img-wrapper"
                            style={{ backgroundColor: "#F29900" }}
                          >
                            <img
                              src={"/assets/icons/search.png"}
                              alt="search"
                            />
                          </div>

                          <p>Search</p>
                        </div>
                      </div>

                      <>
                        {active === 1 ? (
                          <>
                            {/* <div className="text-center pt-4">Coming soon</div> */}

                            <ul
                              className="list-unstyled list-wrapper"
                              style={{
                                maxHeight: mobileView
                                  ? "40vh"
                                  : // : `calc(100vh - ${menuHeight}px`,
                                    `440px`,
                              }}
                            >
                              {ivtsList?.length > 0 ? (
                                ivtsList.map((store) => (
                                  <li
                                    key={store?.ivtsId}
                                    className="cursor"
                                    onClick={() =>
                                      setLetLongObj({
                                        lat: store?.latitude,
                                        lng: store?.longitude,
                                      })
                                    }
                                  >
                                    <div className="map-detail-wrapper d-flex flex-wrap">
                                      <div className="flex-1">
                                        <div className="store-name">
                                          {store?.name}
                                        </div>
                                        <div className="store-address">
                                          {" "}
                                          Address :{" "}
                                          <span className="font-600">
                                            {store?.storeDisplayAddress && (
                                              <span>
                                                {store?.storeDisplayAddress}{" "}
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                        <div className="store-address">
                                          {" "}
                                          Notes :{" "}
                                          <span className="font-600">
                                            {store?.notes}
                                          </span>
                                        </div>
                                        <div
                                          className="store-direction"
                                          onClick={(ev) =>
                                            mapDirection(ev, store)
                                          }
                                        >
                                          DIRECTIONS
                                        </div>
                                      </div>

                                      <div className="store-distance">
                                        {store?.distance} mi
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="px-3 py-2">{noList}</div>
                              )}
                            </ul>
                          </>
                        ) : active === 2 ? (
                          <>
                            <div className="search-input-wrapper py-3 px-3">
                              <input
                                className="form-control"
                                placeholder="Search"
                                onChange={(ev) => handleChange(ev.target.value)}
                                value={inputVal}
                              />
                            </div>
                            <ul
                              className="list-unstyled list-wrapper"
                              style={{
                                maxHeight: mobileView
                                  ? "40vh"
                                  : // : `calc(100vh - ${menuHeight}px`,
                                    `390px`,
                              }}
                            >
                              {searchList?.length > 0 ? (
                                searchList.map((store) => (
                                  // store.storeId !== currentData?.storeId && (
                                  <li
                                    key={store?.storeId}
                                    className="cursor"
                                    onClick={() =>
                                      setLetLongObj({
                                        lat: store?.latitude,
                                        lng: store?.longitude,
                                      })
                                    }
                                  >
                                    <div className="map-detail-wrapper d-flex flex-wrap">
                                      <div className="flex-1">
                                        <div className="store-name">
                                          {store?.name}
                                        </div>
                                        <div className="store-address">
                                          {" "}
                                          Address :{" "}
                                          <span className="font-600">
                                            {store?.storeDisplayAddress && (
                                              <span>
                                                {store?.storeDisplayAddress}{" "}
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                        <div className="store-address">
                                          {" "}
                                          Notes :{" "}
                                          <span className="font-600">
                                            {store?.notes}
                                          </span>
                                        </div>
                                        <div
                                          className="store-direction"
                                          onClick={(ev) =>
                                            mapDirection(ev, store)
                                          }
                                        >
                                          DIRECTIONS
                                        </div>
                                      </div>

                                      <div className="store-distance">
                                        {store?.distance} mi
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="px-3 py-2">{noList}</div>
                              )}
                            </ul>
                          </>
                        ) : (
                          <ul
                            className="list-unstyled list-wrapper"
                            style={{
                              maxHeight: mobileView
                                ? "40vh"
                                : // : `calc(100vh - ${menuHeight}px`,
                                  `440px`,
                            }}
                          >
                            {storesList?.length > 0 ? (
                              storesList.map(
                                (store) =>
                                  store.storeId !== currentData?.storeId && (
                                    <li
                                      key={store?.storeId}
                                      className="cursor"
                                      onClick={() =>
                                        setLetLongObj({
                                          lat: store?.latitude,
                                          lng: store?.longitude,
                                        })
                                      }
                                    >
                                      <div className="map-detail-wrapper d-flex flex-wrap">
                                        <div className="flex-1">
                                          <div className="store-name">
                                            {store?.storeName}
                                          </div>
                                          <div className="store-address">
                                            {" "}
                                            Address :{" "}
                                            <span className="font-600">
                                              {store?.storeDisplayAddress && (
                                                <span>
                                                  {store?.storeDisplayAddress}{" "}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                          <div className="store-address">
                                            {" "}
                                            Notes :{" "}
                                            <span className="font-600">
                                              {store?.notes}
                                            </span>
                                          </div>
                                          <div
                                            className="store-direction"
                                            onClick={(ev) =>
                                              mapDirection(ev, store)
                                            }
                                          >
                                            DIRECTIONS
                                          </div>
                                        </div>

                                        <div className="store-distance">
                                          {store?.distance} mi
                                        </div>
                                      </div>
                                    </li>
                                  )
                              )
                            ) : (
                              <div className="px-3 py-2">{noList}</div>
                            )}
                          </ul>
                        )}
                      </>
                    </div>
                    <MobileMenu
                      noList={noList}
                      storesList={storesList}
                      searchList={searchList}
                      currentData={currentData}
                      getLetLongObj={(lat, lng) =>
                        setLetLongObj({ lat: lat, lng: lng })
                      }
                      setMapDirection={(ev, store) => mapDirection(ev, store)}
                      openScanner={handleScanner}
                      currentMenu={(id) => setCurrentMenu(id)}
                      activeMenu={currentMenu}
                      mobileView={mobileView}
                      searchChange={handleChange}
                      inputVal={inputVal}
                      {...props}
                    />
                  </>
                )}
              </>
            )}

            <div className="dashboard-notify-wrappper w-100 text-center font-16 text-color-primary px-3">
              {!defaultProps && (
                <div>
                  Please allow access to the location and then refresh the
                  application
                </div>
              )}
              {defaultProps && storesList?.length <= 0 && (
                <div>
                  There are no stores available in your area, please check later
                </div>
              )}
            </div>
          </div>
        </div>

        {openSettingsModal && (
          <UserSettingsModal
            open={openSettingsModal}
            closeModal={closeSettingModal}
            setCurrentMenu={setCurrentMenu}
          />
        )}
      </>
    )
  );
};

export default withRouter(Dashboard);

import React, {useState} from "react";
import DropdownComponent from "../_common/dropdown";
import format from "date-fns/format";


  

const ProductCatalougeTable = (props) => {

  return (
    <div className="table-wrapper px-3 pt-3">
     
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "name" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                 
                >
                  <span>S. No.</span>{" "}
                 
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "name" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("name")}
                >
                  <span>Product Name</span>{" "}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="text-center">
                  <span>Product Image</span>
                </th>
                
                <th className="text-center">
                  <span>Status</span>
                </th>
                <th
                  className={`sort-header text-center cursor ${props?.sortObj?.sortVal === "created" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("created")}
                >
                  <span>Created On</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header text-center cursor ${props?.sortObj?.sortVal === "lastModified" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("lastModified")}
                >
                  <span>Updated On</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="text-center">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data, i) => {
                const dropDownActions = [ { text:'Edit', onClick:()=>props?.editData(data) } ];
                if(!data?.isDefault) {
                  dropDownActions?.push({ text:data?.isActive?'Deactivate':'Activate', onClick:()=>props?.toggleData(data) });
                  dropDownActions?.push({ text:'Delete', onClick:()=>props?.deleteData(data) });
                }

                let address = data?.storeNumber + ", " + data?.street;
                if (data?.address) {
                  address = address + ", " + data?.address;
                }
                address = address + ", " + data?.city;
                return (
                  <tr key={data?.storeId || "N/A"}>
                    <td>{i+1}</td>

                    <td>{data?.name || "N/A"}</td>
                    <td><div className="table-product-img text-center">
                        { data?.image ? <img src={data?.image} width={30} alt="img"/> : "N/A" }
                        </div>
                    </td>
                    <td className="text-center">
                      {data?.isActive ? (
                        <span className="table-isactive">Active</span>
                      ) : (
                        <span className="table-isinactive">Inactive</span>
                      )}
                    </td>
                    <td className="text-center">
                      {data.created
                        ? format(new Date(data?.created), "dd/MM/yyyy")
                        : "NA"}
                    </td>
                    <td className="text-center">
                      {data.lastModified
                        ? format(new Date(data?.lastModified), "dd/MM/yyyy")
                        : "NA"}
                    </td>
                    <td className="nowrap text-center">
                    
                    <div>
                        <DropdownComponent 
                        
                        list={dropDownActions}
                        />
   
    </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalougeTable;

export const logOut = (value, link) => {
  // localStorage.removeItem('userType');
  localStorage.removeItem("ecauthtoken");
  localStorage.removeItem("ecsecuretoken");
  localStorage.removeItem("userEmail");

  value.push(link);
};

export const httpHeaderOwn = async (value, link) => {
  let token = localStorage.getItem("userToken");
  let userId = localStorage.getItem("userId");

  if (token && userId) {
    return {
      jwt: token,
      userId: userId,
    };
  } else {
    logOut(value, link);
  }
};

export const validEmail = async (value) => {
  // eslint-disable-next-line
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!value.match(mailformat)) {
    return false;
  } else {
    return true;
  }
};

export const validPostCodeFunc = async (value) => {
  const postformat =
    /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2}$/;
  // var postformat = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
  if (!value.match(postformat)) {
    return false;
  } else {
    return true;
  }
};

export const compareValues = (key) => {
  return function innerSort(a, b) {
    if (typeof a[key] === "number" || b[key] === "number") {
      // return a[key] - b[key];
      if (a[key] === b[key]) return 0;

      return a[key] - b[key];
    }
    if (!a[key] || !b[key]) {
      // property doesn't exist on either object
      return 0;
    }
    return a[key]
      .toString()
      .toLowerCase()
      .localeCompare(b[key].toString().toLowerCase());
  };
};

// Function to export data as a file
export const exportData = (data, fileName, type) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

import {
  postRequest,
  getRequest,
  deleteRequest,
} from "../actionTypes/axiosUtility";
import { GLOBAL_API } from "../actionTypes/types";

export const adminLoginApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/login`, obj);
};

export const adminLoginMFAApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/verify-mfa`, obj);
};

// user api

export const getUsersList = async (query) => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-users${query}`,);
};

export const addUserApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-user`, obj);
};
export const deleteUserApi = async (userId) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-user/${userId}`);
};

export const userEmergencyOverrideApi = async (userId) => {
  return getRequest(`${GLOBAL_API}/admin/v1/emergency-override/${userId}`);
};

// store api

export const getIvtsList = async (query) => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-ivts${query}`);
};

export const getIvtsDownloadList = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/download-ivts`);
};

export const viewIvtsApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-ivts/${id}`);
};

export const addIvtsApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-ivts`, obj);
};

export const updateIvtsApi = async (obj, id) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-ivts/${id}`, obj);
};

export const deleteIvtsApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-ivts/${id}`);
};

export const toggleIvtsApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-ivts/${id}`);
};

// ivts api

// store api

export const getStoreList = async (query) => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-stores${query}`);
};

export const getStoreDownloadList = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/download-stores`);
};
export const viewStoreApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-store/${id}`);
};

export const addStoreApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-store`, obj);
};



export const updateStoreApi = async (obj, id) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-store/${id}`, obj);
};

export const deleteStoreApi = async (userId) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-store/${userId}`);
};
export const toggleStoreApi = async (userId) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-store/${userId}`);
};
// acces code api

export const getAccessCodeListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-access-codes`);
};

export const addAccessCodeApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-access-code`, obj);
};
export const deleteAccessCodeApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-access-code/${id}`);
};

export const editAccessCodeApi = async (obj, id) => {
  return postRequest(`${GLOBAL_API}/admin/v1/edit-access-code/${id}`, obj);
};

export const toggleAccessCodeListApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-access-code/${id}`);
};

export const regenAccessCodeListApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/regen-access-code/${id}`);
};

// video api

export const getVideoListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-videos`);
};

export const addVideoApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/upload-video`, obj);
};
export const deleteVideoApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-video/${id}`);
};

export const toggleVideoApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-video/${id}`);
};

// quiz api

export const getQuizListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-question-bank`);
};

export const addQuizApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-to-question-bank`, obj);
};
export const deleteQuizApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/delete-question-bank/${id}`);
};

export const toggleQuizApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-question-bank/${id}`);
};

export const editQuizApi = async (obj, id) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-question-bank/${id}`, obj);
};

export const validateMailApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/validate-email`, obj);
};

export const genResetApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/gen-reset`, obj);
};

export const checkResetApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/check-reset`, obj);
};

export const resetPasswordApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/reset-password`, obj);
};

export const activateAdminUserApi = (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/activate-user`, obj);
};

export const adminListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/list-admins`);
};

export const addAdminApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/add-admin`, obj);
};

//admin view profile
export const adminViewProfile = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-profile`);
};

//admin password update
export const adminUpdatePassword = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-password`, obj);
};

export const adminResendActivationApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/resend-activation/${id}`);
};
export const adminResetAdminApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/reset-password/${id}`);
};
export const adminToggleAdminApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/toggle-user/${id}`);
};

//admin reports
export const adminUserReportApi = async (obj) => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-users-report?${obj}`);
};

export const adminZonerListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/v1/zones/list`);
};

export const adminStoreReportApi = async (obj) => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-store-report?${obj}`);
};

export const adminUserDataReportApi = async (obj) => {
  return getRequest(
    `${GLOBAL_API}/admin/v1/view-users-transactions-report?${obj}`
  );
};

// EducationManagement

export const getEducationManagementList = async (type) => {
  return getRequest(`${GLOBAL_API}/admin/v1/education/list/${type}`);
};
export const toggleToggleEducationApi = async (type, id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/education/toggle/${type}/${id}`);
};

export const deleteEducationApi = async (type, id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/education/delete/${type}/${id}`);
};

export const addEducationApi = async (type, obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/education/add-new/${type}`, obj);
};

// export const updateStoreApi = async (obj, id) => {
//   return postRequest(`${GLOBAL_API}/admin/v1/update-store/${id}`, obj);
// };

export const addNotificationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/notifications/generate`, obj);
};

export const getNotificationTemplatesList = async (type) => {
  return getRequest(`${GLOBAL_API}/admin/v1/notifications/templates/list`);
};

export const deleteTemplateApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/notifications/templates/${id}`);
};

export const addTemplateApi = async (obj) => {
  return postRequest(
    `${GLOBAL_API}/admin/v1/notifications/templates/create`,
    obj
  );
};

export const sendNotificationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/notifications/generate`, obj);
};

export const getNotificationReportsList = async (type) => {
  return getRequest(`${GLOBAL_API}/admin/v1/notifications/reports/list`);
};

export const addStockApi = async (id, obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-store-stock/${id}`, obj);
};

export const deleteNotificationApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/v1/notifications/${id}`);
};


export const viewKioskApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/v1/view-store/${id}`);
};

export const addIvtsStockApi = async (id, obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/update-ivts-stock/${id}`, obj);
};

// support api

export const getSupportListApi = async (query) => {
  if(query){
    return getRequest(`${GLOBAL_API}/admin/v1/support${query}`);
  }
  return getRequest(`${GLOBAL_API}/admin/v1/support`);
};

export const supportCloseApi = async (id, obj) => {
  return postRequest(`${GLOBAL_API}/admin/v1/support/close/${id}`, obj);
};

//  configaration api

export const getConfigrationResponseApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/configuration/v1/fetch`);
}

export const updateConfigrationResponseApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/configuration/v1/update`, obj);
}

// product catalouge API

export const addProductApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/admin/products/v1/update`, obj);
};

export const getProductListApi = async () => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/list`);
};

export const toggleProductApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/toggle/${id}`);
};

export const deleteProductApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/admin/products/v1/delete/${id}`);
};


// manage stores api

export const getStoreDropdownApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/list/drop-down?storeId=${id}`);
};

export const getSKUStoreListApi = async (id) => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/stores/sku/list/${id}`);
};
export const getSKUAddRemoveApi = async (action, storeId, orgProductId) => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/stores/sku/update/${action}/${storeId}/${orgProductId}`);
};
export const postSKUStockUpdateApi = async (storeId,orgProductId,obj ) => {
  return postRequest(`${GLOBAL_API}/admin/products/v1/stores/sku/stock-update/${storeId}/${orgProductId}`, obj);
};

export const getSKUStockToggleApi = async (storeId,orgProductId ) => {
  return getRequest(`${GLOBAL_API}/admin/products/v1/stores/sku/toggle/${storeId}/${orgProductId}}`);
};









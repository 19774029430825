/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HeadingComponent from "./headingComponent";
import {
  userSelfRegister,
  userLevelProgressApi,
  userRegisterVerifyNumber,
  userRegisterNumber,
  userCheckDob,
  fetchUserProfileApi,
} from "../../user/_userActions";
import { GENDER_LIST } from "../../actionTypes/types";
import DatePicker from "react-date-picker";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import TimerComp from "../../_shared/timer";
import TermsComponent from "./termsComponent";
import RegSuccessModal from "./regSuccessModal";
import InputComponent from "./inputComponent";
import { validPostCodeFunc } from "../../_shared/commonFunction";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

const RegisterComponent = (props) => {
  const [values, setValues] = useState({
    phone: "",
    postCode: "",
    birthDate: new Date("fake date"),
    gender: "",
  });
  const [emptyMobile, setEmptyMobile] = useState(false);
  const [postalcode, setPostalcode] = useState("");
  const [emptyPostalCode, setEmptyPostalCode] = useState(false);
  const [dob, setDob] = useState(null);
  const [emptyDob, setEmptyDob] = useState(false);
  const [genderMsg, setGenderMsg] = useState("");
  const [accessCodeRequired, setAccessCodeRequired] = useState(false);
  const [showUnlimitedTransaction, setShowUnlimitedTransaction] =
    useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [emptyAccessCode, setEmptyAccessCode] = useState(false);
  const [toggleOtp, setToggleOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [emptyOtp, setEmptyOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [termsAccept, setTermsAccept] = useState(false);
  const [openRegSuccessModal, setOpenRegSuccessModal] = useState();
  const [regSuccessMsg, setRegSuccessMsg] = useState(``);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [height, setHeight] = useState(0);
  const [validPostcodeVal, setValidPostcodeVal] = useState(false);
  const [allowedTransactionLimit, setAllowedTransactionLimit] = useState(1);
  const [isUnlimitedTransaction, setIsUnlimitedTransaction] = useState(false);

  useEffect(() => {
    if (dob && postalcode) {
      checkDob();
    }
  }, [dob, postalcode]);

  const checkDob = async () => {
    try {
      globalLoader(true);
      let obj = {
        dateOfBirth: dob,
        postalCode: postalcode,
      };
      let res = await userCheckDob(obj);
      if (res?.data?.status === 200) {
        setAccessCodeRequired(res?.data?.data?.accessCodeRequired);
        if (res?.data?.data?.accessCodeRequired) {
          setShowUnlimitedTransaction(res?.data?.data?.showUnlimitedOption);
        }
        if (res?.data?.data?.warning?.show) {
          setWarningMsg(res?.data?.data?.warning?.message);
        } else {
          setWarningMsg(``);
        }
      } else {
        setWarningMsg("");
      }

      globalLoader(false);
    } catch (err) {
      globalAlert("error", err?.response?.data?.message);
      globalLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleValidation = (value = null) => {
    const { phone, gender } = values;
    let isValid = true;
    if (!phone) {
      setEmptyMobile(true);
      isValid = false;
    } else if (phone.length < 10) {
      isValid = false;
    }
    if (!toggleOtp && phoneVerify) {
      if (!postalcode) {
        setEmptyPostalCode(true);
        isValid = false;
      }
      if (!validPostcodeVal) {
        isValid = false;
      }
      if (!dob) {
        setEmptyDob(true);
        isValid = false;
      }
      if (!gender) {
        setGenderMsg("Gender is mandatory");
        isValid = false;
      }
      if (accessCodeRequired && !accessCode) {
        setEmptyAccessCode(true);
        isValid = false;
      }
    }

    if (toggleOtp && !value && !otp) {
      setEmptyOtp(true);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);

      if (phoneVerify) {
        let obj = {
          mobile: values.phone,
          postalCode: postalcode.replace(/\s+/g, ""),
          dateOfBirth: dob,
          gender: values.gender,
          accessCode: accessCode ? accessCode : null,
          allowedTransactionLimit:
            isUnlimitedTransaction && showUnlimitedTransaction
              ? -1
              : parseInt(allowedTransactionLimit),
        };
        let res = await userSelfRegister(obj);
        if (res?.data?.status === 200) {
          setRegSuccessMsg(res?.data?.message);
          setOpenRegSuccessModal(true);
        } else {
          globalAlert("error", res?.data?.message);
        }

        globalLoader(false);
      } else if (toggleOtp) {
        let obj = {
          mobile: values.phone,
          otp: otp,
        };

        let res = await userRegisterVerifyNumber(obj);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
          globalLoader(false);
          setPhoneVerify(true);
          setToggleOtp(false);
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } else {
        let obj = {
          mobile: values.phone,
        };

        let res = await userRegisterNumber(obj);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
          globalLoader(false);
          setToggleOtp(true);
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      }
    }
  };

  const onCloseRegSuccessModal = async () => {
    setOpenRegSuccessModal(false);
    const userProfileApi = await fetchUserProfileApi();
    if (userProfileApi?.data?.status === 200 && userProfileApi?.data?.data?.questions?.show) {
      props.history.push(`/${props.roleType}/missing-profile`);
    } else {
      let progress = await userLevelProgressApi();
      if (progress?.data?.status === 200) {
        if (!progress?.data?.data?.skipVideosPage) {
          props.history.push(`/${props.roleType}/video`);
        } else if (
          progress?.data?.data?.skipVideosPage &&
          !progress?.data?.data?.skipQuizPage
        ) {
          props.history.push(`/${props.roleType}/quiz`);
        } else if (progress?.data?.data?.quizLevelPassed) {
          props.history.push(`/${props.roleType}/dashboard`);
        } else {
          props.history.push(`/${props.roleType}/video`);
        }
      } else {
        globalAlert("error", progress?.data?.message);
        window.location.reload();
      }
    }
  };

  const handleOtp = async () => {
    if (await handleValidation(true)) {
      globalLoader(true);
      let res = await userRegisterNumber({ mobile: values?.phone });
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setOtpSent(false);
        setEmptyOtp(false);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    }
  };

  const handlePostalCode = async (ev) => {
    // let data = ev?.target?.value;
    let data = ev;
    if (data.length < 8) {
      let value = data.split(" ").join("");
      setPostalcode(value);
      setValidPostcodeVal(await validPostCodeFunc(value));
    }
  };

  const setTimerOver = () => {
    setOtpSent(true);
  };

  const handleTransactionLimit = (val) => {
    const positiveIntegerPattern = /^[0-9]*$/;
    if (val === "" || positiveIntegerPattern.test(val)) {
      setAllowedTransactionLimit(val);
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setAllowedTransactionLimit(0);
    }
    setIsUnlimitedTransaction(checked);
  };

  return (
    <div>
      {termsAccept ? (
        <div>
          <HeadingComponent text="Register" getHeight={(ev) => setHeight(ev)} />

          <div
            className="reg-screen-wrapper pt-4"
            style={{
              marginTop: `${height}px`,
              minHeight: `calc(100vh - ${height}px)`,
            }}
          >
            <div className="fix-width-wrapper px-3 mx-auto">
              <form className="form-own" onSubmit={handleSubmit}>
                {!toggleOtp && (
                  <>
                    {!phoneVerify && (
                      <div className="form-group">
                        <p>
                          We need to verify your mobile number by sending you a
                          one time password
                        </p>
                        <p>
                          Please enter your Mobile No. without any prefixes and
                          press submit button
                        </p>
                      </div>
                    )}
                    <div className="form-group">
                      <InputComponent
                        placeholder="Phone Number"
                        label="Mobile No."
                        type="text"
                        name="phone"
                        inputFor="number"
                        theme="white"
                        maxLength="11"
                        value={values?.phone}
                        disabled={phoneVerify ? true : false}
                        autoComplete="off"
                        handleCallback={
                          phoneVerify
                            ? null
                            : (val) => {
                                const name = "phone";
                                setValues({ ...values, [name]: val });
                              }
                        }
                      />
                      <div className="error-text">
                        {!values?.phone && emptyMobile && (
                          <span>Phone is required</span>
                        )}
                        {values?.phone && values?.phone?.length < 10 && (
                          <span>More than 10 digits required</span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {phoneVerify && (
                  <>
                    <div className="form-group">
                      <InputComponent
                        placeholder="Postcode"
                        label="Postcode"
                        type="text"
                        name="postCode"
                        inputFor="text"
                        theme="white"
                        value={postalcode}
                        autoComplete="off"
                        handleCallback={(val) => handlePostalCode(val)}
                        capsValue={true}
                      />
                      <div className="error-text">
                        {!postalcode && emptyPostalCode && (
                          <span>Post Code is required</span>
                        )}
                        {postalcode && !validPostcodeVal && (
                          <span>Invalid Postcode </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group form-group-lg position-relative">
                      <label className="datepicker-label" htmlFor="birthDate">
                        Date of Birth
                      </label>
                      <div className="own-datepicker">
                        <DatePicker
                          onChange={setDob}
                          value={dob}
                          maxDate={new Date()}
                          format={"dd/MM/yyy"}
                        />
                      </div>
                      <div className="error-text">
                        {!dob && emptyDob && (
                          <span>Date of Birth is required</span>
                        )}
                      </div>
                    </div>

                    {warningMsg && (
                      <div className="text-danger py-3 font-12">
                        {warningMsg}
                      </div>
                    )}

                    {accessCodeRequired && (
                      <>
                        <div className="form-group form-group-lg">
                          <InputComponent
                            placeholder="Access Code"
                            label="Access Code"
                            type="text"
                            name="accessCode"
                            inputFor="text"
                            theme="white"
                            value={accessCode}
                            autoComplete="off"
                            maxLength={6}
                            handleCallback={(val) => setAccessCode(val)}
                          />
                          <div className="error-text">
                            {!accessCode && emptyAccessCode && (
                              <span>Access code is required</span>
                            )}
                            {accessCode && accessCode?.length < 5 && (
                              <span>More than 5 digits required</span>
                            )}
                          </div>
                        </div>
                        <Box
                          display={{ xs: "flex" }}
                          flexDirection={{ xs: "row" }}
                          justifyContent={{ xs: "space-between" }}
                          alignItems={{ xs: "center" }}
                        >
                          <div className="form-group form-group-lg mr-2">
                            <InputComponent
                              disabled={isUnlimitedTransaction}
                              placeholder="No of transactions"
                              label="No of transactions"
                              type="number"
                              name="allowedTransactionLimit"
                              inputFor="text"
                              theme="white"
                              value={allowedTransactionLimit}
                              autoComplete="off"
                              maxLength={3}
                              handleCallback={(val) =>
                                handleTransactionLimit(val)
                              }
                            />
                          </div>
                          {showUnlimitedTransaction && (
                            <div className="form-group form-group-lg ml-2">
                              <FormControlLabel
                                label="Allow unlimited transactions?"
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={isUnlimitedTransaction}
                                    onChange={handleCheckboxChange}
                                  />
                                }
                              />
                            </div>
                          )}
                        </Box>
                      </>
                    )}

                    <div className="form-group">
                      <label className="reg-text" htmlFor="gender">
                        Gender
                      </label>
                      <div className="radio-wrapper">
                        {GENDER_LIST.map((i) => {
                          return (
                            <div key={i.id} className="radio" id="gender">
                              <input
                                id={i.id}
                                className="hidden radio-label"
                                type="radio"
                                value={i.value}
                                name="gender"
                                checked={values.gender === i.value}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                              <label className="btn-label" htmlFor={i.id}>
                                <p>{i.text}</p>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <div className="error-text">
                        {!values.gender && genderMsg && (
                          <span>{genderMsg}</span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {toggleOtp && (
                  <>
                    <div className="form-group pt-3">
                      <p>We have sent an OTP to your mobile number</p>
                      <div className="form-group">
                        <InputComponent
                          placeholder="Phone Number"
                          label="Mobile No."
                          type="text"
                          name="phone"
                          inputFor="number"
                          theme="white"
                          maxLength="11"
                          value={values?.phone}
                          disabled={true}
                          autoComplete="off"
                          handleCallback={null}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <InputComponent
                        placeholder="Enter OTP"
                        label="Enter OTP"
                        type="text"
                        name="otp"
                        inputFor="text"
                        theme="white"
                        maxLength={null}
                        value={otp}
                        autoComplete="off"
                        handleCallback={(val) => {
                          setOtp(val);
                        }}
                      />
                      <div className="error-text font-12 pl-2">
                        {!otp && emptyOtp && <span>OTP is required</span>}
                      </div>
                    </div>

                    <div className="resend-otp-txt text-center mb-3 mt-3">
                      {!otpSent ? (
                        <TimerComp setTimerOver={setTimerOver} />
                      ) : (
                        <span className="cursor" onClick={handleOtp}>
                          Resend OTP
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div className="form-group mt-3 pt-2">
                  {phoneVerify ? (
                    <div className="reg-btn-wrapper">
                      <button
                        className=""
                        onClick={() => props?.history?.push("/user/login")}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="">
                        Save Details
                      </button>
                    </div>
                  ) : (
                    <button type="submit" className="btnFooter w-100 d-block">
                      {!toggleOtp && !phoneVerify
                        ? "Submit"
                        : toggleOtp
                        ? "Submit"
                        : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <TermsComponent accept={() => setTermsAccept(true)} />
      )}
      {openRegSuccessModal && (
        <RegSuccessModal
          open={openRegSuccessModal}
          message={regSuccessMsg}
          closeModal={onCloseRegSuccessModal}
        />
      )}
    </div>
  );
};

export default withRouter(RegisterComponent);

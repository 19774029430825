import React, { useState, useEffect } from "react";
import HeadingComponent from "../template/_common/headingComponent";
import { Link } from "react-router-dom";
import VideoModal from "../template/user/videoModal";
import { bypassAccessCodeApi, userLevelProgressApi } from "./_userActions";
import { globalAlert, globalLoader } from "../actions/commonActions";
import { withRouter } from "react-router-dom";
import { videoIsWatchedApi } from "./_userActions";
import AccessCodeModal from "../template/user/accessCodeModal";
import { logOut } from "../_shared/commonFunction";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import InputComponent from "../template/_common/inputComponent";

const UserVideo = (props) => {
  const [videos, setVideos] = useState([]);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [allWatched, setAllWatched] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [totalVideos, setTotalVideos] = useState(0);
  const [nextVideo, setNextVideo] = useState(null);
  const [haltObj, setHaltObj] = useState("");
  const [halt, setHalt] = useState(false);
  const [nextPageText, setNextPageText] = useState(`Continue to Quiz`);
  const [nextPageLink, setNextPageLink] = useState(`/user/quiz`);

  useEffect(() => {
    callBasicApi({}); // eslint-disable-next-line
  }, []);

  const callBasicApi = async ({ quizCheck = true }) => {
    try {
      globalLoader(true);
      let res = await userLevelProgressApi(quizCheck);
      if (res?.data?.status === 200) {
        if (res?.data?.data?.haltProgress?.halt) {
          setHalt(true);
          setHaltObj(res?.data?.data?.haltProgress);
        } else if (
          res?.data?.data?.videosDetails?.length > 0 &&
          !res?.data?.data?.skipVideosPage
        ) {
          setNextPageText(
            res?.data?.data?.skipQuizPage
              ? `Continue to Dashboard`
              : `Continue to Quiz`
          );
          setNextPageLink(
            res?.data?.data?.skipQuizPage ? `/user/dashboard` : `/user/quiz`
          );
          setTotalVideos(res?.data?.data?.videosDetails?.length);
          let arr = [];
          res.data.data.videosDetails.forEach((v, index) => {
            let obj = v;
            obj.isWatched = v?.watched;
            obj.count = index + 1;
            arr.push(obj);
          });
          let isAllWatched = res.data.data.videosDetails.every(
            (x) => x.watched
          );
          setVideos(arr);
          setAllWatched(isAllWatched);
        } else if (
          res?.data?.data?.skipVideosPage &&
          !res?.data?.data?.skipQuizPage
        ) {
          props.history.push(`/user/quiz`);
        } else if (res?.data?.data?.quizLevelPassed) {
          props.history.push(`/user/dashboard`);
        }
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const closeOpenVideoModal = () => {
    setOpenVideoModal(false);
    setCurrentData(null);
  };

  const openVideo = (item) => {
    let currIndex = videos.findIndex((v) => v === item);
    let newArr = videos
      .slice(currIndex, totalVideos)
      .concat(videos.slice(0, currIndex));
    newArr = newArr.filter((v) => {
      if (v.videoId !== item.videoId && !v.isWatched) {
        return v;
      }
    });
    setNextVideo(newArr[0]?.count);
    setCurrentData(item);
    setOpenVideoModal(true);
  };

  const handleClick = () => {
    const data = videos.find((item) => item.isWatched === false);
    openVideo(data);
  };

  const openNextVideo = (vid) => {
    // let index = videos.findIndex((v) => v.videoTitle === i);
    // if (index + 1 === totalVideos) {
    //   handleClick();
    // } else {
    //   openVideo(videos[index + 1]);
    // }
    let nextVid;
    let currIndex = videos.findIndex((v) => v.videoId === vid);
    for (let i = currIndex + 1; i < videos.length; i++) {
      if (!videos[i].isWatched) {
        nextVid = videos[i];
        break;
      }
    }
    if (!nextVid) {
      for (let i = 0; i < currIndex; i++) {
        if (!videos[i].isWatched) {
          nextVid = videos[i];
          break;
        }
      }
    }
    openVideo(nextVid);
  };

  const handleAccessModal = () => {
    setOpenAccessModal(true);
  };

  const closeAccessModal = async (val = null) => {
    if (val) {
      globalLoader(true);
      let res = await bypassAccessCodeApi(val);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        props.history.push("/user/dashboard");
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    } else {
      setOpenAccessModal(false);
    }
  };

  //mark video as watched api call
  const handleVideoEnd = async (id) => {
    globalLoader(true);
    let res = await videoIsWatchedApi(id);
    if (res?.data?.status === 200) {
      callBasicApi({ quizCheck: false });
    } else {
      globalAlert("error", res?.data?.message);
      globalLoader(false);
    }
  };

  //bypass video and quiz with access code api

  return (
    <>
      <div>
        {halt && (
          <div className="halt-message">
            <HeadingComponent text={haltObj?.heading} />

            <div className="halt-txt">{haltObj?.message}</div>
          </div>
        )}
        {!openVideoModal && !halt && <HeadingComponent text="Videos" />}
        <div className={`${!halt ? "video-list-wrapper" : ""} px-3 mx-auto`}>
          <div className="video-list px-4">
            {videos?.length > 0 &&
              videos.map((item) => {
                return (
                  <div
                    className="video-group pr-2 d-flex flex-wrap"
                    key={item?.videoId}
                  >
                    <div className="mr-2 my-auto checkbox-own">
                      <div className="round">
                        <input
                          type="checkbox"
                          id={item?.videoTitle}
                          checked={item?.isWatched}
                          className="checkbox-round"
                          readOnly
                        />
                        <label
                          htmlFor={item?.videoTitle}
                          className="checkbox-round-label"
                        ></label>
                      </div>
                      <div className="round-checkbox"></div>
                    </div>
                    <span className="text-style flex-1 my-auto">
                      {item?.videoTitle}
                    </span>
                    <button
                      className="btnPrimary btnVideo my-auto ml-auto"
                      onClick={() => {
                        openVideo(item);
                      }}
                    >
                      Watch
                    </button>
                  </div>
                );
              })}
          </div>
          {!openVideoModal && (
            <div
              className={`access-wrapper d-flex fixed-bottom justify-content-center ${
                halt ? "less-padding" : ""
              }`}
            >
              <button
                className="btnFooter btn-min-lg"
                onClick={handleAccessModal}
              >
                Enter Access Code
              </button>
            </div>
          )}
        </div>
        {!openVideoModal && !halt && (
          <div className="fixed-bottom  stripe py-3 border-top fixed-bottom-own">
            <div className="col-md-12 text-center">
              {allWatched ? (
                <Link to={nextPageLink}>
                  <button className="btnFooter btn-min-lg">
                    {nextPageText} <i className="fas fa-check"></i>
                  </button>
                </Link>
              ) : (
                <button className="btnFooter btn-min-lg" onClick={handleClick}>
                  Watch Video <i className="fas fa-check"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          closeModal={closeOpenVideoModal}
          data={currentData}
          openNextVideo={openNextVideo}
          totalVideo={videos?.length}
          videoEnded={handleVideoEnd}
          allWatched={allWatched}
          nextVideo={nextVideo}
          nextPageLink={nextPageLink}
          nextPageText={nextPageText}
        />
      )}

      {openAccessModal && (
        <AccessCodeModal
          open={openAccessModal}
          closeModal={closeAccessModal}
          askTxnsAllowed={haltObj?.askTxnsAllowed}
          // verifyAccessCode={verifyAccessCode}
        />
      )}
    </>
  );
};

export default withRouter(UserVideo);

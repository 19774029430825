import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import {
  getConfigrationResponseApi,
  updateConfigrationResponseApi,
} from "./_actions";
import { logOut } from "../_shared/commonFunction";
import InputComponent from "../template/_common/inputComponent";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import ReactHtmlParser from "react-html-parser";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  formExtraQuizConfigElements,
  formExtraVideoConfigElements,
  formSupportElements,
} from "./configrationField";

const MAX_LENGTH = 300;

const ManageConfigration = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [type, setType] = useState("open");
  const [editorState, setEditorState] = useState(null);
  const [preview, setPreview] = useState(false);
  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  const [config, setConfig] = useState({
    messages: {
      registration: EditorState.createEmpty(),
      quizFailed: EditorState.createEmpty(),
      quizSuccess: EditorState.createEmpty(),
    },
    registerConfig: {
      ageRange: { min: "", max: "" },
      videosConfig: { required: false, count: 0 },
      questionsConfig: [
        { choiceType: "single_select", required: false, type: "ethnicity" },
        {
          choiceType: "single_select",
          required: false,
          type: "employment_status",
        },
        {
          choiceType: "single_select",
          required: false,
          type: "sexual_orientation",
        },
        { choiceType: "single_select", required: false, type: "main_language" },
        { choiceType: "multi_select", required: false, type: "disability" },
        {
          choiceType: "multi_select",
          required: false,
          type: "special_educational_needs",
        },
      ],
    },
    quizConfig: {
      required: false,
      questions: "",
      passPercent: "",
      maxAttempts: "",
      validityInYears: "",
    },
    supportConfig: {
      email: "",
      contactNumber: "",
      content: "",
      notes: "",
    },
  });

  // Function to convert HTML to EditorState
  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Prevent errors if null
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, [type]);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await getConfigrationResponseApi(
        type === "close" ? "?showClosed=true" : null
      );
      if (res?.data?.status === 200) {
        console.log("res", res);
        setAllowView(true);
        let currData = res?.data?.data?.configuration;
        setConfig(currData);
        // Set editor states
        setEditorStates({
          registration: convertHtmlToEditorState(
            currData.messages.registration
          ),
          quizFailed: convertHtmlToEditorState(currData.messages.quizFailed),
          quizSuccess: convertHtmlToEditorState(currData.messages.quizSuccess),
        });
        setAllowView(true);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  // Handle nested object updates
  const handleNestedInputChange = (section, nestedSection, field, value) => {
    console.log("section", section);
    console.log("nestedSection", nestedSection);
    console.log("field", field);
    console.log("value", value);
    setConfig((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [nestedSection]: {
          ...prev[section][nestedSection],
          [field]: value,
        },
      },
    }));
  };

  // Handle editor state changes
  const handleEditorChange = (field, newState) => {
    setEditorStates((prev) => ({
      ...prev,
      [field]: newState,
    }));

    setConfig((prev) => ({
      ...prev,
      messages: {
        ...prev.messages,
        [field]: draftToHtml(convertToRaw(newState?.getCurrentContent() ?? "")),
      },
    }));
  };

  // Handle checkbox state change
  const handleCheckboxChange = (section, field, value) => {
    setConfig((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const [editorStates, setEditorStates] = useState({
    registration: EditorState.createEmpty(),
    quizFailed: EditorState.createEmpty(),
    quizSuccess: EditorState.createEmpty(),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      configuration: {
        messages: {
          registration: config?.messages?.registration ?? "",
          quizFailed: config?.messages?.quizFailed ?? "",
          quizSuccess: config?.messages?.quizSuccess ?? "",
        },
        registerConfig: {
          ageRange: config.registerConfig.ageRange,
          videosConfig: config.registerConfig.videosConfig,
          questionsConfig: config.registerConfig.questionsConfig,
        },
        quizConfig: config.quizConfig,
        supportConfig: config.supportConfig,
      },
    };

    console.log("Payload:- ", payload);
    // Send the payload to API
    try {
      globalLoader(true);
      const res = await updateConfigrationResponseApi(payload);

      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        await callBasicApi(); // Refresh data after successful submission
      } else {
        globalAlert("error", res?.data?.message);
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
      globalAlert(
        "error",
        error?.response?.data?.message || "Failed to update configuration"
      );
    } finally {
      globalLoader(false);
    }
  };

  const getLengthOfSelectedText = (editorState) => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (startKey === endKey) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = (fieldName) => {
    const currentContent = editorStates[fieldName].getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText(editorStates[fieldName]);

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  const handlePastedText = (fieldName, pastedText) => {
    const currentContent = editorStates[fieldName].getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText(editorStates[fieldName]);

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      return "handled";
    }
  };

  const [accordionToggle, setAccordionToggle] = useState(false);

  const handleToggle = () => {
    setAccordionToggle(!accordionToggle);
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="admin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Configration</p>
                </div>

                <div className="tab-own-wrapper text-center pt-1">
                  <ul className="tab-wrapper-list d-inline-flex flex-wrap list-unstyled">
                    <li
                      className={`${type === "open" ? "active" : ""}`}
                      onClick={() => setType("open")}
                    >
                      Registration Process
                    </li>

                    <li
                      className={`${type === "close" ? "active" : ""}`}
                      onClick={() => setType("close")}
                    >
                      Support
                    </li>
                  </ul>
                </div>

                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div
                  style={{ backgroundColor: "#ffffff", padding: "20px 0px" }}
                >
                  <div className="container-fluid">
                    <div className="row admin-controls">
                      <form
                        onSubmit={handleSubmit}
                        className="form-own pt-3 px-3"
                      >
                        {type === "open" ? (
                          <div className="row">
                            <div className="col-4 px-2">
                              <div className="form-own-group">
                                <InputComponent
                                  placeholder="Min age"
                                  label={
                                    <>
                                      Min Age (Years){" "}
                                      <span className="color-red">*</span>
                                    </>
                                  }
                                  type="number"
                                  name="min"
                                  theme="white"
                                  value={
                                    config?.registerConfig?.ageRange?.min ?? ""
                                  }
                                  handleCallback={(value) =>
                                    setConfig((prev) => ({
                                      ...prev,
                                      registerConfig: {
                                        ...prev.registerConfig,
                                        ageRange: {
                                          ...prev.registerConfig.ageRange,
                                          min: Number(value),
                                        },
                                      },
                                    }))
                                  }
                                  maxLength="3"
                                  min="0"
                                  max="120"
                                />
                                <div className="error-text">
                                  {/* {!phone && phoneErr && <span>Mobile Number is required</span>}
                                                    {phone && phone.length < 10 && (
                                                      <span>Mobile Number must have 10 digits</span>
                                                    )} */}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 px-2">
                              <div className="form-own-group">
                                <InputComponent
                                  placeholder="Max age"
                                  label={
                                    <>
                                      Max Age (Years){" "}
                                      <span className="color-red">*</span>
                                    </>
                                  }
                                  type="number"
                                  name="max"
                                  theme="white"
                                  value={config?.registerConfig?.ageRange?.max}
                                  handleCallback={(value) =>
                                    setConfig((prev) => ({
                                      ...prev,
                                      registerConfig: {
                                        ...prev.registerConfig,
                                        ageRange: {
                                          ...prev.registerConfig.ageRange,
                                          max: Number(value),
                                        },
                                      },
                                    }))
                                  }
                                  maxLength="3"
                                  min="0"
                                  max="120"
                                />
                                <div className="error-text">
                                  {/* {!phone && phoneErr && <span>Mobile Number is required</span>}
                                                    {phone && phone.length < 10 && (
                                                      <span>Mobile Number must have 10 digits</span>
                                                    )} */}
                                </div>
                              </div>
                            </div>
                            {/* Quiz Required */}

                            <div className="col-md-6">
                              <div className="form-own-group">
                                <label className="form-own-label">
                                  Quiz Required
                                </label>
                                <div>
                                  <input
                                    type="checkbox"
                                    name="quizRequired"
                                    id="quizYes"
                                    checked={
                                      config.quizConfig.required === true
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "quizConfig",
                                        "required",
                                        true
                                      )
                                    }
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="quizRequired"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px", marginRight: "16px" }}
                                  >
                                    Yes
                                  </label>

                                  <input
                                    type="checkbox"
                                    name="quizRequired"
                                    id="quizNo"
                                    checked={
                                      config.quizConfig.required === false
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "quizConfig",
                                        "required",
                                        false
                                      )
                                    }
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="quizRequired"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px" }}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                {config.quizConfig.required === true &&
                                  formExtraQuizConfigElements.map(
                                    (item, index) => (
                                      <div className="col-3 px-2" key={index}>
                                        <div className="form-own-group">
                                          <InputComponent
                                            placeholder={item?.placeholder}
                                            label={
                                              <>
                                                {item?.label}{" "}
                                                {/* <span className="color-red">*</span> */}
                                              </>
                                            }
                                            type={item?.type}
                                            name={item?.name}
                                            theme="white"
                                            value={
                                              config?.quizConfig?.[
                                                item?.name
                                              ] ?? ""
                                            }
                                            handleCallback={(value) =>
                                              setConfig((prev) => ({
                                                ...prev,
                                                quizConfig: {
                                                  ...prev.quizConfig,
                                                  [item?.name]:
                                                    parseFloat(value),
                                                },
                                              }))
                                            }
                                            maxLength={item?.maxLength}
                                            min={item?.min}
                                            max={item?.max}
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>

                            {/* Show Videos During Registration */}
                            <div className="col-md-6">
                              <div className="form-own-group">
                                <label className="form-own-label">
                                  Show Videos During Registration
                                </label>
                                <div>
                                  <input
                                    type="checkbox"
                                    name="showVideos"
                                    id="showVideosYes"
                                    checked={
                                      config.registerConfig?.videosConfig
                                        .required === true
                                    }
                                    onChange={() =>
                                      handleNestedInputChange(
                                        "registerConfig",
                                        "videosConfig",
                                        "required",
                                        true
                                      )
                                    }
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="showVideos"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px", marginRight: "16px" }}
                                  >
                                    Yes
                                  </label>

                                  <input
                                    type="checkbox"
                                    name="showVideos"
                                    id="showVideosNo"
                                    checked={
                                      config.registerConfig?.videosConfig
                                        .required === false
                                    }
                                    onChange={(e) =>
                                      handleNestedInputChange(
                                        "registerConfig",
                                        "videosConfig",
                                        "required",
                                        false
                                      )
                                    }
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="showVideos"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px" }}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                {config.registerConfig?.videosConfig
                                  .required === true &&
                                  formExtraVideoConfigElements.map(
                                    (item, index) => (
                                      <div className="col-3 px-2" key={index}>
                                        <div className="form-own-group">
                                          <InputComponent
                                            placeholder={item?.placeholder}
                                            label={
                                              <>
                                                {item?.label}{" "}
                                                {/* <span className="color-red">*</span> */}
                                              </>
                                            }
                                            type={item?.type}
                                            name={item?.name}
                                            theme="white"
                                            value={
                                              config?.registerConfig
                                                ?.videosConfig?.[item?.name] ??
                                              ""
                                            }
                                            handleCallback={(value) =>
                                              setConfig((prev) => ({
                                                ...prev,
                                                registerConfig: {
                                                  ...prev.registerConfig,
                                                  videosConfig: {
                                                    ...prev.registerConfig
                                                      .videosConfig,
                                                    [item?.name]: Number(value),
                                                  },
                                                },
                                              }))
                                            }
                                            maxLength={item?.maxLength}
                                            min={item?.min}
                                            max={item?.max}
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                            {/* Message */}
                            <div className="col-12">
                              {/* registration message */}
                              <div className="form-own-group own-textarea-wrapper position-relative">
                                <label
                                  className={`custom-label ${
                                    preview ? "primary-color" : ""
                                  }`}
                                >
                                  Registration Message{" "}
                                  {/* <small className="font-12">
                                  (Max 300 character)
                                </small>{" "} */}
                                  <span className="color-red">*</span>
                                </label>

                                {preview ? (
                                  <div className="overflow-wrap">
                                    <div>
                                      {editorState &&
                                        ReactHtmlParser(
                                          draftToHtml(
                                            convertToRaw(
                                              editorState.getCurrentContent()
                                            )
                                          )
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <Editor
                                    editorState={editorStates.registration}
                                    toolbarClassName="own-editor-toolbar"
                                    wrapperClassName="own-editor-wrapper"
                                    editorClassName="own-editor-box"
                                    onEditorStateChange={(newState) =>
                                      handleEditorChange(
                                        "registration",
                                        newState
                                      )
                                    }
                                    handlePastedText={(text) =>
                                      handlePastedText("registration", text)
                                    }
                                    handleBeforeInput={() =>
                                      handleBeforeInput("registration")
                                    }
                                    toolbar={{
                                      options: [
                                        "inline",
                                        "fontSize",
                                        "list",
                                        "textAlign",
                                      ],
                                      inline: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                        ],
                                      },
                                    }}
                                  />
                                )}
                              </div>

                              {/* quiz message */}
                              <div className="form-own-group own-textarea-wrapper position-relative mt-5">
                                <label
                                  className={`custom-label ${
                                    preview ? "primary-color" : ""
                                  }`}
                                >
                                  Quiz Pass Message{" "}
                                  <span className="color-red">*</span>
                                </label>

                                {preview ? (
                                  <div className="overflow-wrap">
                                    <div>
                                      {editorState &&
                                        ReactHtmlParser(
                                          draftToHtml(
                                            convertToRaw(
                                              editorState.getCurrentContent()
                                            )
                                          )
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <Editor
                                    editorState={editorStates.quizSuccess}
                                    toolbarClassName="own-editor-toolbar"
                                    wrapperClassName="own-editor-wrapper"
                                    editorClassName="own-editor-box"
                                    onEditorStateChange={(newState) =>
                                      handleEditorChange(
                                        "quizSuccess",
                                        newState
                                      )
                                    }
                                    handlePastedText={(text) =>
                                      handlePastedText("quizSuccess", text)
                                    }
                                    handleBeforeInput={() =>
                                      handleBeforeInput("quizSuccess")
                                    }
                                    toolbar={{
                                      options: [
                                        "inline",
                                        "fontSize",
                                        "list",
                                        "textAlign",
                                      ],
                                      inline: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                        ],
                                      },
                                    }}
                                  />
                                )}
                              </div>

                              {/* quiz failed message */}
                              <div className="form-own-group own-textarea-wrapper position-relative mt-5">
                                <label
                                  className={`custom-label ${
                                    preview ? "primary-color" : ""
                                  }`}
                                >
                                  Quiz Failed Message{" "}
                                  <span className="color-red">*</span>
                                </label>

                                {preview ? (
                                  <div className="overflow-wrap">
                                    <div>
                                      {editorState &&
                                        ReactHtmlParser(
                                          draftToHtml(
                                            convertToRaw(
                                              editorState.getCurrentContent()
                                            )
                                          )
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <Editor
                                    editorState={editorStates.quizFailed}
                                    toolbarClassName="own-editor-toolbar"
                                    wrapperClassName="own-editor-wrapper"
                                    editorClassName="own-editor-box"
                                    onEditorStateChange={(newState) =>
                                      handleEditorChange("quizFailed", newState)
                                    }
                                    handlePastedText={(text) =>
                                      handlePastedText("quizFailed", text)
                                    }
                                    handleBeforeInput={() =>
                                      handleBeforeInput("quizFailed")
                                    }
                                    toolbar={{
                                      options: [
                                        "inline",
                                        "fontSize",
                                        "list",
                                        "textAlign",
                                      ],
                                      inline: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                        ],
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            {/* Questions */}
                            <div className="col-12 mt-5">
                              <div className="accordion" id="accordionExample">
                                <div
                                  className="accordion-item"
                                  onClick={handleToggle}
                                  style={{
                                    width: "100%",
                                    background: "#eeecef",
                                    padding: "10px 0px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                    style={{
                                      fontSize: "18px",
                                      width: "100%",
                                      fontWeight: 800,
                                      padding: "0px 10px",
                                      color: "#3e2f91",
                                    }}
                                  >
                                    Additional Registration Questions
                                  </h2>
                                  {/* {config?.registerConfig?.questionsConfig.map(
                                    (item, index) => (
                                      <div key={index}
                                        id="collapseOne"
                                        className={`accordion-collapse collapse ${
                                          accordionToggle ? "show" : ""
                                        }`}
                                        style={{
                                          width: "100%",

                                          background: "#f9f9f9",
                                          padding: "16px 10px",
                                          display:'flex',
                                          justifyContent:'space-between',
                                          alignItems:'center'
                                        }}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <strong>{item?.display}</strong>{" "}
                                        </div>
                                        <div className="table-switch d-flex">
                                          <input
                                            className="toggle-input"
                                            type="checkbox"
                                            id={item?.type}
                                            onChange={() =>
                                              setConfig((prev) => ({
                                                ...prev,
                                                registerConfig: {
                                                  ...prev.registerConfig,
                                                  questionsConfig: prev.registerConfig.questionsConfig.map(
                                                    (q) =>
                                                      q.type === item?.type
                                                        ? {
                                                            ...q,
                                                            required: !q.required,
                                                          }
                                                        : q
                                                  ),
                                                },
                                            }))
                                            }
                                            checked={item?.required}
                                          />
                                          <label
                                            className="toggle-label"
                                            htmlFor={item?.type}
                                          >
                                            Toggle
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )} */}
                                  <table className="table table-striped table-hover table-admin">
                                    <thead className="table-head">
                                      <tr>
                                        <th scope="col">Question</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Options</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {config?.registerConfig?.questionsConfig.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <th scope="row">{item?.title}</th>
                                            <td>{item?.questionType}</td>
                                            <td>
                                              <select
                                                class="form-control"
                                                id="exampleFormControlSelect1"
                                              >
                                                {item?.options?.map(
                                                  (opt, idx) => (
                                                    <option key={idx}>
                                                      {opt}
                                                    </option> // Added key={idx}
                                                  )
                                                )}
                                              </select>
                                            </td>
                                            <td>
                                               {/* Enabled Checkbox */}
                                               <div className="table-switch d-flex ">
                                                <div
                                                  style={{
                                                    display: "flex",fontSize:'11px',
                                                    alignItems: "center",marginTop:'8px',marginRight:'10px'
                                                  }}
                                                >
                                                  Enabled
                                                </div>
                                                <input
                                                  className="toggle-input"
                                                  type="checkbox"
                                                  id={`enabled-${item?.type}`}
                                                  onChange={() =>
                                                    setConfig((prev) => ({
                                                      ...prev,
                                                      registerConfig: {
                                                        ...prev.registerConfig,
                                                        questionsConfig:
                                                          prev.registerConfig.questionsConfig.map(
                                                            (q) =>
                                                              q.type ===
                                                              item?.type
                                                                ? {
                                                                    ...q,
                                                                    enabled:
                                                                      !q.enabled,
                                                                  }
                                                                : q
                                                          ),
                                                      },
                                                    }))
                                                  }
                                                  checked={item?.enabled}
                                                />
                                                <label
                                                  className="toggle-label"
                                                  htmlFor={`enabled-${item?.type}`}
                                                >
                                                  Enabled
                                                </label>
                                              </div>
                                              {/* Required Checkbox */}
                                              {item?.enabled &&
                                              
                                              <div className="table-switch d-flex">
                                              <div
                                                  style={{
                                                    display: "flex",fontSize:'11px',
                                                    alignItems: "center",marginTop:'8px',marginRight:'6px'
                                                  }}
                                                >
                                                  Required
                                                </div>
                                                <input
                                                  className="toggle-input"
                                                  type="checkbox"
                                                  id={`required-${item?.type}`}
                                                  onChange={() =>
                                                    setConfig((prev) => ({
                                                      ...prev,
                                                      registerConfig: {
                                                        ...prev.registerConfig,
                                                        questionsConfig:
                                                          prev.registerConfig.questionsConfig.map(
                                                            (q) =>
                                                              q.type ===
                                                              item?.type
                                                                ? {
                                                                    ...q,
                                                                    required:
                                                                      !q.required,
                                                                  }
                                                                : q
                                                          ),
                                                      },
                                                    }))
                                                  }
                                                  checked={item?.required}
                                                />
                                                <label
                                                  className="toggle-label"
                                                  htmlFor={`required-${item?.type}`}
                                                >
                                                  Required
                                                </label>
                                              </div>
                                            }
                                             
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            {formSupportElements.map((item, index) => (
                              <div
                                className={`col-${item?.md} px-2`}
                                key={index}
                              >
                                <div className="form-own-group own-textarea-wrapper position-relative mt-5">
                                  <label
                                    className={`custom-label ${
                                      preview ? "primary-color" : ""
                                    }`}
                                  >
                                    {item?.label}{" "}
                                    {/* <span className="color-red">*</span> */}
                                  </label>
                                  <InputComponent
                                    placeholder={item?.placeholder}
                                    // label={
                                    //   <>
                                    //     {/* Post Code <span className="color-red">*</span> */}
                                    //   </>
                                    // }
                                    type={item?.type}
                                    name={item?.name}
                                    inputFor="text"
                                    theme="white"
                                    multiline={item?.multiline}
                                    value={
                                      config?.supportConfig?.[item?.name] ?? ""
                                    }
                                    handleCallback={(value) =>
                                      setConfig((prev) => ({
                                        ...prev,
                                        supportConfig: {
                                          ...prev.supportConfig,
                                          [item?.name]: value,
                                        },
                                      }))
                                    }
                                    maxLength={item?.maxLength}
                                  />
                                  <div className="error-text">
                                    {/* {!editorState && bodyErr && <span>Field is required</span>} */}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {/* checkbox */}
                            {/* Show Videos During Registration */}
                            {/* <div className="col-md-8 mt-5">
                              <div className="form-own-group">
                                <label className="form-own-label">
                                  Do you want to receive emails for new support
                                  tickets
                                </label>
                                <div>
                                  <input
                                    type="checkbox"
                                    name="showVideos"
                                    id="showVideosYes"
                                    // checked={sendNotification}
                                    // onChange={() => setSendNotification((a) => !a)}
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="showVideos"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px", marginRight: "16px" }}
                                  >
                                    Yes
                                  </label>

                                  <input
                                    type="checkbox"
                                    name="showVideos"
                                    id="showVideosNo"
                                    // checked={sendNotification}
                                    // onChange={() => setSendNotification((a) => !a)}
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                    }}
                                  />
                                  <label
                                    htmlFor="showVideos"
                                    className="form-own-label pl-2 position-relative"
                                    style={{ top: "-3px" }}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        )}
                        <div className="admin-btn">
                          <button
                            onClick={() => props.closeModal()}
                            className="btn secondary-btn mr-2"
                            type="button"
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            className="btn primary-btn ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(ManageConfigration);

export const formRegistrationElements = [
  {
    type: "input",
    label: "Min Age (Years)",
    name: "min",
    placeholder: "Min age",
    valuePath: ["registerConfig", "ageRange", "min"],
    maxLength: 3,
    min: 0,
    max: 120,
    required: true,
  },
  {
    type: "input",
    label: "Max Age (Years)",
    name: "max",
    placeholder: "Max age",
    valuePath: ["registerConfig", "ageRange", "max"],
    maxLength: 3,
    min: 0,
    max: 120,
    required: true,
  },
  {
    type: "checkbox",
    label: "Quiz Required",
    name: "quizRequired",
    valuePath: ["quizConfig", "required"],
    options: ["Yes", "No"],
  },
  {
    type: "checkbox",
    label: "Show Videos During Registration",
    name: "showVideos",
    valuePath: ["videoConfig", "showDuringRegistration"],
    options: ["Yes", "No"],
  },
  {
    type: "editor",
    label: "Registration Message",
    name: "registration",
    valuePath: ["editorStates", "registration"],
  },
  {
    type: "editor",
    label: "Quiz Pass Message",
    name: "quizSuccess",
    valuePath: ["editorStates", "quizSuccess"],
  },
  {
    type: "editor",
    label: "Quiz Failed Message",
    name: "quizFailed",
    valuePath: ["editorStates", "quizFailed"],
  },
];

export const formExtraQuizConfigElements = [
  {
    type: "number",
    label: "Question",
    name: "questions",
    placeholder: "questions",
    valuePath: ["quizConfig", "questions"],
    maxLength: 3,
    min: 0,
    max: 100,
    required: true,
  },
  {
    type: "number",
    label: "Percetage",
    name: "passPercent",
    placeholder: "Percentage",
    valuePath: ["quizConfig", "passPercent"],
    maxLength: 3,
    min: 0,
    max: 100,
    required: true,
  },
  {
    type: "number",
    label: "Max Attempts",
    name: "maxAttempts",
    placeholder: "Max Attempts",
    valuePath: ["quizConfig", "maxAttempts"],
    maxLength: 3,
    min: 0,
    max: 100,
    required: true,
  },
  {
    type: "number",
    label: "Validity (Years)",
    name: "validityInYears",
    placeholder: "Validity",
    valuePath: ["quizConfig", "validityInYears"],
    maxLength: 3,
    min: 0,
    max: 100,
    required: true,
  },
];

export const formExtraVideoConfigElements = [
  {
    type: "count",
    label: "Count",
    name: "count",
    placeholder: "count",
    valuePath: ["registerConfig", "videosConfig", "count"],
    maxLength: 3,
    min: 0,
    max: 100,
    required: true,
  },
];

export const formSupportElements = [
    {
        type: "input",
        label: "Support Email",
        name: "email",
        placeholder: "Email",
        valuePath: ["supportConfig", "email"],
        maxLength: 100,
        // min: 0,
        // max: 120,
        required: true,
        md: 6,
    },
    {
        type: "number",
        label: "Support Phone",
        name: "contactNumber",
        placeholder: "Phone",
        valuePath: ["supportConfig", "contactNumber"],
        maxLength: 10,
        // min: 0,
        // max: 120,
        required: true,
        md: 6,
    },
    {
        type: "input",
        label: "Notes",
        name: "notes",
        placeholder: "Notes",
        valuePath: ["supportConfig", "notes"],
        maxLength: 250,  
        // min: 0,
        // max: 120,
        required: true,
        multiline: true,
        md:12,
    },
    {
        type: "input",
        label: "Content",
        name: "content",
        placeholder: "Content",
        valuePath: ["supportConfig", "content"],
        maxLength: 250,
        // min: 0,
        // max: 120,
        required: true,
        multiline: true,
        md:12,
    },
    
]